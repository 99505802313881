import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { ConsultantBio, ConsultantName } from "./styled.js";
import HourlyButton from "../Buttons/HourlyButton";

const ConsultantCard = props => {
  const consultant = props.consultant;
  return (
    <div className="showcase__item">
      <Link to={`/consultants/${consultant.slug}`}>
        <figure className="card">
          <Img
            className="card_profile-image"
            fluid={consultant.consultantImage.fluid}
          />

          <figcaption className="card__caption">
            <ConsultantBio
              dangerouslySetInnerHTML={{
                __html: consultant.bioSnippet
              }}
            />
            <ConsultantName>{consultant.consultantName}</ConsultantName>
            <HourlyButton text="View Profile" size="sm" />
          </figcaption>
        </figure>
      </Link>
    </div>
  );
};
export default ConsultantCard;
