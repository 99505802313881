import React from "react";

const HourlyButton = props => {
  return (
    <div>
      <button
        className={`btn btn--${props.size} type--uppercase`}
        href="#"
        target=""
        rel="noopener noreferrer"
      >
        <span className="btn__text">{props.text}</span>
      </button>
    </div>
  );
};
export default HourlyButton;
