import styled from "styled-components";
export const ConsultantBio = styled.h4`
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 500;
  @media (max-width: 480px) and (min-width: 320px) {
    font-size: 1em;
  }
`;
export const ConsultantName = styled.h5`
  font-size: 1em;
  margin-bottom: 24px;
  @media (max-width: 480px) and (min-width: 320px) {
    font-size: 0.9em;
  }
`;
